<template>
  <div class="chart-loader">
    <span class="material-icons chart-icon" aria-hidden="true">bar_chart</span>
    <div class="scrim" aria-hidden="true"></div>
  </div>
</template>

<style lang="scss" scoped>
  .chart-loader {
    width: 100%;
    height: 100%;
    background: var(--color-contrast-low);
    background-size: 200% 100%;

    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .scrim {
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(to right, transparent, var(--color-contrast-lower), transparent);
      animation: scrim 2s infinite ease;
    }

    .chart-icon {
      font-size: 200px;
      color: var(--color-contrast-high);
      z-index: 1;
    }
  }

  @keyframes scrim {
    //gets as close to 60fps as possible!
    0% {
		  transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  } 
</style>

<script>
  export default {
    name: 'ChartLoader',
    props: {
    },
    data: () => ({

    })
  }
</script>
